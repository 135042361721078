import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import Select from "react-select";
import Item from "./GrantsList/Item";
import Pagination from "./Pagination";

interface Option<T = string | number> {
  label: string;
  value: T;
}

interface Props {
  grants: GrantsList.Grant[];
  categories: GrantsList.Category[];
  strings: GrantsList.Localizations;
}

interface State {
  year?: number;
  month?: number;
  months: number[];
  page: number;
  category: number;
  search: boolean;
  searchQuery: string;
}

export default function GrantsList(props: Props) {
  const { grants, categories, strings } = props;
  const perPage = 200;

  const initialCategories = categories.filter((c) => c.parent == null);
  const years = Array.from(new Set(grants.map((g) => g.year))).sort(
    (a, b) => b - a
  );

  const monthsForYear = (year: number) => {
    let filtered = grants;
    if (year) {
      filtered = filtered.filter((g) => g.year === year);
    }
    return Array.from(new Set(filtered.map((g) => g.month))).sort(
      (a, b) => b - a
    );
  };

  const [state, setState] = useState<State>({
    year: null,
    month: null,
    months: monthsForYear(years[0]),
    page: 1,
    category: null,
    search: false,
    searchQuery: ""
  });

  const scrollRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const searchString = (grant: GrantsList.Grant) => {
      const categoryName = categories.filter(
        (c) => c.id === grant.category_id
      )[0].full_name;
      return `${grant.name} ${grant.title} ${categoryName}`;
    };

    grants.forEach((g) => (g.searchString = searchString(g)));
  }, [grants, categories]);

  const changeCategory = (opt: Option<number>) => {
    setState({ ...state, page: 1, category: opt.value || null });
  };

  const changeMonth = (opt: Option<number>) => {
    setState({ ...state, page: 1, month: opt.value || null });
  };

  const changeYear = (opt: Option<number>) => {
    let month = state.month;
    const year = opt.value || null;
    const months = monthsForYear(year);
    if (!year || months.indexOf(month) === -1) {
      month = null;
    }
    setState({
      ...state,
      page: 1,
      year: opt.value,
      month: month,
      months: months
    });
  };

  const changeSearchQuery = (evt: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, searchQuery: evt.target.value });
  };

  const filteredGrants = () => {
    let filtered = grants;
    const { year, month, category, search, searchQuery } = state;

    if (search) {
      if (searchQuery.length >= 2) {
        filtered = filtered.filter((g) =>
          fuzzyMatch(g.searchString, searchQuery)
        );
      } else {
        filtered = [];
      }
    } else {
      if (year) {
        filtered = filtered.filter((g) => g.year === year);
      }
      if (month) {
        filtered = filtered.filter((g) => g.month === month);
      }
      if (category) {
        const ids = [category].concat(
          categories.filter((c) => c.parent === category).map((c) => c.id)
        );
        filtered = filtered.filter((g) => ids.includes(g.category_id));
      }
    }
    return filtered;
  };

  const fuzzyMatch = (str: string, query: string) => {
    const pattern = query
      .toLowerCase()
      .split(" ")
      .reduce((a, b) => a + ".*" + b);
    return new RegExp(pattern).test(str.toLowerCase());
  };

  const onPageChange = (evt: { selected: number }) => {
    setState({ ...state, page: evt.selected + 1 });
    scrollToGrants();
  };

  const pageCount = () => Math.ceil(filteredGrants().length / perPage);

  const pageHrefBuilder = () => null;

  const valueOption = (options: Option[], value: number) => {
    value = value || 0;
    return options.filter((o) => o.value === value)[0];
  };

  const pagedGrants = (grants: GrantsList.Grant[]) => {
    const page = state.page;
    return grants.slice((page - 1) * perPage, page * perPage);
  };

  const renderFilter = () => {
    const mapOptions = (items: number[], blankLabel: string) => {
      return [{ value: 0, label: blankLabel }].concat(
        items.map((y) => ({ value: y, label: `${y}` }))
      );
    };

    const yearOptions = mapOptions(years, strings.all_years);

    const categoryOptions = [
      { value: 0, label: strings.all_categories }
    ].concat(
      initialCategories.map((c: GrantsList.Category) => ({
        value: c.id,
        label: c.name
      }))
    );

    const monthOptions = [{ value: 0, label: strings.all_months }].concat(
      state.months.map((m) => ({
        value: m,
        label: strings.month_names[m]
      }))
    );

    return (
      <div className="filter">
        <div className="label">{strings.grants}</div>
        <div className="input year">
          <Select
            options={yearOptions}
            value={valueOption(yearOptions, state.year)}
            onChange={changeYear}
            classNamePrefix="react-select"
          />
        </div>
        <div className="input month">
          <Select
            options={monthOptions}
            value={valueOption(monthOptions, state.month)}
            onChange={changeMonth}
            isDisabled={!state.year}
            classNamePrefix="react-select"
          />
        </div>
        <div className="input category">
          <Select
            options={categoryOptions}
            value={valueOption(categoryOptions, state.category)}
            onChange={changeCategory}
            classNamePrefix="react-select"
          />
        </div>
        <div className="search-toggle">
          <a href="#" onClick={toggleSearch}>
            {strings.search_all_grants}
          </a>
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search-bar">
        <input
          type="text"
          autoFocus
          name="q"
          ref={searchRef}
          placeholder={strings.search_all_grants}
          onChange={changeSearchQuery}
        />
        <button type="button" className="close" onClick={toggleSearch}>
          Close
        </button>
      </div>
    );
  };

  const scrollToGrants = () => {
    if (!scrollRef.current || !window) return;

    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 120;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  const toggleSearch = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setState({ ...state, search: !state.search, searchQuery: "" });
  };

  const filtered = filteredGrants();
  const paged = pagedGrants(filtered);

  return (
    <div className="grants-list" ref={scrollRef}>
      {!state.search && renderFilter()}
      {state.search && renderSearch()}
      <ul className="index">
        {paged.map((g) => (
          <Item
            key={g.id}
            strings={strings}
            categories={categories}
            grant={g}
          />
        ))}
      </ul>
      {filtered.length > perPage && (
        <Pagination
          hrefBuilder={pageHrefBuilder}
          onPageChange={onPageChange}
          page={state.page}
          pageCount={pageCount()}
          strings={strings}
        />
      )}
    </div>
  );
}
